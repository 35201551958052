import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'

class PortfolioPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark

    return (
      <Layout
        location={this.props.location}
        title={post.frontmatter.title}
        image={post.frontmatter.image.childImageSharp.full.src}
        description={post.excerpt}
      >
        <h1>{post.frontmatter.title}</h1>
        <p>{post.frontmatter.date}</p>
        <Img fluid={post.frontmatter.image.childImageSharp.full} />
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        {post.frontmatter?.other_images && (
          <Img fluid={post.frontmatter.other_images.childImageSharp.full} />
        )}
      </Layout>
    )
  }
}

export default PortfolioPostTemplate

export const pageQuery = graphql`
  query PortfolioPostByPath($slug: String!) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      id
      html
      excerpt
      frontmatter {
        title
        date(locale: "cs", formatString: "l")
        image {
          childImageSharp {
            full: fluid(maxWidth: 740) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        other_images {
          childImageSharp {
            full: fluid(maxWidth: 740) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
